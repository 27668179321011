<template>
  <div class="renew-subscription">
    <div class="renew-subscription__content">
      <p class="renew-subscription__text">
        {{ $t('subscription_expired.title') }}
      </p>
      <base-button
        :label="$t('subscription_expired.buttons.renew')"
        class="renew-subscription__button desk"
        :to="{ name: 'subscription-expired' }"
      />
      <base-button
        variant="icon"
        :icon="{ name: 'angle-down', dir: 'global', fill: '#fff', rotate: -90 }"
        class="renew-subscription__button mob"
        :to="{ name: 'subscription-expired' }"
      />
    </div>
  </div>
</template>

<style lang="scss">
.renew-subscription {
  width: 100%;
  background: #222;

  &__content {
    max-width: 1260px;
    margin: 0 auto;
    padding: 5px 20px;

    gap: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-family: 'Nunito', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.9;
    color: #fff;
  }

  &__button {
    width: fit-content !important;

    color: #fff !important;

    font-family: 'Nunito', sans-serif;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal !important;
    padding: 4px 16px !important;

    border-radius: 12px !important;
    border: 2px solid #fff !important;

    background: transparent !important;

    &.desk {
      @media (max-width: 480px) {
        display: none;
      }
    }

    &.mob {
      display: none;
      border: none !important;
      padding: 3px !important;
      @media (max-width: 480px) {
        display: flex;
      }
    }
  }
}
</style>
