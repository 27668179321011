import { computed, ref } from 'vue';

export const useSwiperControl = () => {
  const swipeController = ref(null);

  const currentSlide = computed(() => swipeController.value?.activeIndex + 1);
  const totalSlides = computed(() => swipeController.value?.slides.length);
  const setSwiper = (value) => {
    swipeController.value = value;
  };
  const nextSlide = () => {
    swipeController.value.slideNext();
  };

  const prevSlide = () => {
    swipeController.value.slidePrev();
  };

  return {
    nextSlide,
    prevSlide,
    setSwiper,
    currentSlide,
    totalSlides,
  };
};
