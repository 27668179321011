const TESTS_URLS = {
  tests: '/api/v1/product/tests/:id',
  total_result: '/api/v1/product/tests/total-result',
  test_questions: '/api/v1/product/tests/:id/questions',
  test_result: 'api/v1/product/tests/:id/result',
};

export default (api) => ({
  getAll: () => api.get(TESTS_URLS.tests),
  getTotalResult: () => api.get(TESTS_URLS.total_result),
  getQuestions: (id) => api.get(TESTS_URLS.test_questions, { url_params: { id } }),
  getTest: (id) => api.get(TESTS_URLS.tests, { url_params: { id } }),
  sendScores: ({ id, data }) => api.post(TESTS_URLS.test_result, data, { url_params: { id } }),
  testResult: (id) => api.get(TESTS_URLS.test_result, { url_params: { id } }),
});
