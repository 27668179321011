import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { API } from '@/api/index.js';
import { useLoading } from '@/composables/useLoading.js';
import { progressBarOptions } from '@/static/moods-colors/progress-bar-options.js';
import { TestsBorders } from '@/static/tests-settings/index.js';
import { useI18n } from 'vue-i18n';
import { useMainStore } from '@/stores/MainStore.js';
import landingParameters from '@/static/landing-params/initial-results.json';
export const useTestsStore = defineStore('TestsStore', () => {
  const tests = ref([]);
  const testQuestions = ref(null);
  const currentTest = ref(null);
  const quizResults = ref(null);
  const generalResults = ref(null);
  const router = useRouter();
  const { isLoading, setLoaderState } = useLoading();
  const { t } = useI18n();
  const main = useMainStore();

  const initCohortData = computed(() => main.usersMeta?.meta_data?.cohort_keyword || 'MOD');
  const calculateResult = (value, id) => {
    let level;
    const generalBorders = {
      low: 25,
      middle: 50,
      high: 75,
    };
    const borders = TestsBorders[`test_${id}`] || generalBorders;
    const editedValue = parseInt(value);

    if (editedValue === 0) {
      level = landingParameters[initCohortData.value].level;
    } else {
      if (editedValue < borders.low) level = 'low';
      else if (editedValue >= borders.low && editedValue < borders.middle) level = 'mid';
      else if (editedValue >= borders.middle && editedValue < borders.high) level = 'moderate';
      else level = 'high';
    }

    return {
      container: progressBarOptions[level].underlay,
      progress: progressBarOptions[level].bar,
      text: progressBarOptions[level].text,
      title: t(`depression_levels.${progressBarOptions[level].title}`),
      smile: progressBarOptions[level].icon,
      level: progressBarOptions[level].level,
    };
  };

  const testProgress = computed(() =>
    Math.round((quizResults.value?.score / quizResults.value?.total) * 100),
  );

  const generalProgressOptions = computed(() => calculateResult(generalResults.value));

  const getAllTests = async () => {
    try {
      const {
        data: { data },
        status,
      } = await API.tests.getAll();
      if (status === 200) tests.value = data;
    } catch (error) {
      console.error(error);
    }
  };
  const getTestQuestions = async (id) => {
    setLoaderState(true);
    try {
      const {
        data: { data },
        status,
      } = await API.tests.getQuestions(id);

      if (status === 200) {
        testQuestions.value = await data;
        router.push({ name: 'tests-passing', params: { id } });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderState(false);
    }
  };
  const getCurrentTest = async (id) => {
    setLoaderState(true);
    try {
      const {
        data: { data },
        status,
      } = await API.tests.getTest(id);
      if (status === 200) currentTest.value = data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderState(false);
    }
  };
  const getTotalResult = async (clickid) => {
    try {
      const {
        data: { data },
        status,
      } = await API.tests.getTotalResult();

      if (status === 200) {
        generalResults.value =
          parseInt(data.total_result) || landingParameters[initCohortData.value].value;

        if (parseInt(data.total_result) === 0) {
          main.getUsersMeta(clickid);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const sendScores = async (id, answers) => {
    setLoaderState(true);
    const payload = { id, data: answers };
    try {
      const {
        data: { data },
        status,
      } = await API.tests.sendScores(payload);

      if (status === 200) {
        quizResults.value = data;
        await getAllTests();
        await getTotalResult();
        router.push({ name: 'tests-result', params: { id } });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderState(false);
    }
  };
  const getTestResult = async (id) => {
    try {
      const {
        data: { data },
        status,
      } = await API.tests.testResult(id);

      if (status === 200) {
        quizResults.value = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    tests,
    testQuestions,
    currentTest,
    quizResults,
    testProgress,
    generalResults,
    generalProgressOptions,
    isLoading,
    calculateResult,
    getAllTests,
    getTestQuestions,
    getCurrentTest,
    getTotalResult,
    sendScores,
    getTestResult,
  };
});
