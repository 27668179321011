<template>
  <div class="products">
    <div class="products__text">
      <h2 class="products__title title title-lg">
        {{ t('home.yourProducts.title') }}
      </h2>

      <p class="products__description body body-xl">
        {{ t('home.yourProducts.description') }}
        <span class="products__description products__description--small body body-md">{{
          t('home.yourProducts.description_small')
        }}</span>
      </p>
    </div>
    <ul class="products__list desktop">
      <product-item v-for="item in main.products" :key="item.id" :item="item" :locked="item.lock" />
    </ul>

    <swiper
      :spaceBetween="30"
      :modules="[Pagination]"
      @swiper="setSwiper($event)"
      class="products__list mob"
    >
      <swiper-slide v-for="item of main.products" :key="item.id" class="reviews__slide">
        <product-item :item="item" :locked="item.lock" />
      </swiper-slide>
    </swiper>
    <div class="products__footer">
      <base-button
        variant="icon"
        :icon="{ name: 'angle-down', dir: 'global', fill: '#222', rotate: 90 }"
        class="products__footer-prev"
        @click="prevSlide"
      />
      <p class="products__footer-counter">
        {{ currentSlide }}/ <span class="products__footer-counter--last">{{ totalSlides }}</span>
      </p>
      <base-button
        variant="icon"
        :icon="{ name: 'angle-down', dir: 'global', fill: '#222', rotate: -90 }"
        class="products__footer-next"
        @click="nextSlide"
      />
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import { useMainStore } from '@/stores/MainStore.js';
import ProductItem from '@/components/pages/Home/ProductItem.vue';
import { useI18n } from 'vue-i18n';
import { useSwiperControl } from '@/composables/useSwiperControl.js';
import BaseButton from '@/components/ui/BaseButton.vue';
const main = useMainStore();
const { t } = useI18n();

const { nextSlide, prevSlide, setSwiper, currentSlide, totalSlides } = useSwiperControl();
</script>

<style lang="scss" scoped>
.products {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  z-index: 20;

  @media (max-width: 899px) {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__list {
    list-style: none;
    display: flex;
    align-items: stretch;

    gap: 20px;

    @media (max-width: 899px) {
      flex-direction: column;
      width: 100%;
    }

    &.desktop {
      @media (max-width: 480px) {
        display: none;
      }
    }

    &.mob {
      display: none;

      @media (max-width: 480px) {
        display: flex;
      }
    }
  }

  &__text {
    max-width: 360px;

    @media (max-width: 1275px) {
      max-width: unset;
    }
  }

  &__title {
    margin-bottom: 20px;
    @media (max-width: 480px) {
      margin-bottom: 8px;
    }
  }

  &__description {
    @media (max-width: 480px) {
      font-size: 13px;
      margin-bottom: 12px;
    }

    &--small {
      display: block;
      margin-top: 10px;

      @media (max-width: 480px) {
        font-size: inherit;
        display: inline;
        margin-top: 0;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;

    display: none;

    @media (max-width: 480px) {
      display: flex;
    }

    &-next {
      background: #cad4a6;
    }

    &-prev {
      background: #cad4a6;
    }

    &-counter {
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -2px;

      &--last {
        font-size: 14px;
        color: #abbf62;
      }
    }
  }
}
</style>
