<template>
  <div class="profile">
    <div class="profile__body">
      <div class="account">
        <div class="account__info">
          <div class="account__avatar">
            <p class="account__first-letter">{{ user.name.charAt(0) }}</p>
          </div>
          <div class="account__user">
            <span class="account__name title title-md">{{ user.name }}</span>
            <span class="account__email body body-xxl">{{ user.email }}</span>
          </div>
        </div>
        <p class="account__subscription body body-lg">
          {{ $t('profile.subscription') }}
          <span class="account__subscription--bold">{{ subscriptionMessage }}</span>
        </p>
      </div>
      <div class="profile__content">
        <div class="profile-manager">
          <button class="profile-manager__button" @click="toggleList('profile')">
            <div class="profile-manager__button-name">
              <SVGComponent :path="{ name: 'user', dir: 'profile' }" />
              <span class="profile-manager__button-title">{{ $t('profile.profile_info') }}</span>
            </div>
            <SVGComponent
              class="arrow"
              :class="{ 'arrow-collapsed': profileCollapsed }"
              :path="{ name: 'angle-down', dir: 'global' }"
            />
          </button>

          <Collapse :when="profileCollapsed" class="collapsed">
            <div class="profile-manager__all-inputs profile-manager__all-inputs--padding">
              <base-input-field
                v-model="auth.inputs.profile.name.value"
                :id="auth.inputs.profile.name.key"
                :key="auth.inputs.profile.name.key"
                :input="auth.inputs.profile.name"
                :error="auth.inputs.profile.name.error || auth.inputs.profile.name.serverError"
                :form="auth.inputs.profile"
                :placeholder="$t('profile.accountPage.enterUserName')"
                :label="$t('profile.accountPage.name')"
                variant="white"
                class="profile-manager__input"
              />

              <base-select
                :options="langs"
                :form="auth.inputs.profile.language"
                @change="emit('change-input-value', $event)"
                :label="$t('profile.accountPage.selectLanguage')"
                track-by="id"
              />

              <base-select
                :options="timeZones"
                disabled
                :form="auth.inputs.profile.timezone"
                @change="emit('change-input-value', $event)"
                :label="$t('profile.accountPage.timeZone')"
                track-by="label"
              />
            </div>
          </Collapse>
        </div>
        <div class="profile-manager">
          <button class="profile-manager__button" @click="toggleList('pass')">
            <div class="profile-manager__button-name">
              <SVGComponent :path="{ name: 'lock', dir: 'home' }" />
              <span class="profile-manager__button-title">{{
                $t('profile.accountPage.changePassword')
              }}</span>
            </div>
            <SVGComponent
              class="arrow"
              :class="{ 'arrow-collapsed': passwordCollapsed }"
              :path="{ name: 'angle-down', dir: 'global' }"
            />
          </button>

          <Collapse :when="passwordCollapsed" class="collapsed">
            <div class="profile-manager__all-inputs">
              <base-input-field
                v-for="input in auth.inputs.profile_passwords"
                v-model="input.value"
                :id="input.key"
                :key="input.key"
                :input="input"
                :error="input.error || input.serverError"
                :form="auth.inputs.profile_passwords"
                :placeholder="$t(`profile.changePasswordPage.${input.key}.placeholder`)"
                :label="$t(`profile.changePasswordPage.${input.key}.label`)"
                variant="white"
                class="changePassword__input"
              />
            </div>
          </Collapse>
        </div>
      </div>
      <div class="profile__buttons">
        <base-button
          variant="text"
          reverse
          label="Support"
          :icon="{ name: 'support', dir: 'profile' }"
          class="profile__button"
          :to="{ name: 'support' }"
          @click="setEvent('support')"
        />

        <base-button
          variant="text"
          reverse
          :label="$t('profile.accountPage.logout')"
          :icon="{ name: 'logout', dir: 'profile' }"
          class="profile__button"
          @click="emit('logout')"
        />
      </div>
    </div>
    <div class="profile__actions">
      <div class="profile__actions-container">
        <base-button
          :label="$t('profile.cancel')"
          variant="inversed"
          class="profile__action cancel button button-lg"
          @click="handleCancel"
        />
        <base-button
          :label="$t('profile.saveChanges')"
          :disabled="!auth.saveEnabled || auth.isLoading"
          class="profile__action save button button-lg"
          @click="emit('save')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { Collapse } from 'vue-collapsed';
import { useAuthStore } from '@/stores/AuthStore.js';
import BaseInputField from '@/components/ui/BaseInputField.vue';
import { useRouter } from 'vue-router';
import { useDataLayer } from '@/composables/useDataLayer.js';
const auth = useAuthStore();

const profileCollapsed = ref(false);
const passwordCollapsed = ref(false);

const { setEvent } = useDataLayer();

const emit = defineEmits(['change-input-value', 'save', 'logout']);

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  subscriptionMessage: {
    type: String,
    default: '',
  },
  inputError: {
    default: false,
  },
  langs: {
    type: Array,
    default: () => [],
  },
  timeZones: {
    type: Array,
    default: () => [],
  },
  selectedLang: {
    type: Object,
  },
  selectedTimeZone: {
    type: Object,
  },
});

const router = useRouter();

const toggleList = (button) => {
  if (button === 'pass') {
    passwordCollapsed.value = !passwordCollapsed.value;
  }
  if (button === 'profile') {
    profileCollapsed.value = !profileCollapsed.value;
  }
};

const handleCancel = () => {
  auth.resetProfileValues();
  router.push({ name: 'home' });
};
</script>

<style lang="scss" scoped>
@include slide-vertical-to-up(0.5s, slide-vertical-to-up, ease);
.profile {
  display: flex;
  flex-direction: column;

  &__body {
    padding-bottom: 80px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 0;
    border-bottom: 1px solid #eeece8;
    border-top: 1px solid #eeece8;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 8px;
  }

  &__button {
    color: $main-100;
  }

  &__actions {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    padding: 16px;
    background: #fff;
    filter: drop-shadow(0px -4px 4px rgba(99, 101, 92, 0.06));

    z-index: 102;

    &-container {
      display: flex;
      gap: 7px;

      max-width: 500px;
      margin: 0 auto;
    }
  }
}

.account {
  padding-bottom: 16px;

  &__first-letter {
    font-size: 50px;

    @media (max-width: 899px) {
      font-size: 20px;
    }
  }

  &__info {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
  }

  &__avatar {
    width: 48px;
    height: 48px;
    background: $violet-10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    & .ava {
      fill: $main-100;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__email {
    color: $gray-50;
  }

  &__subscription {
    color: $gray-color;

    &--bold {
      color: $main-100;
      font-weight: 700;
    }
  }
}

.profile-manager {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__button {
    padding: 8px;
    padding-right: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      display: flex;
      align-items: center;
      gap: 12px;

      & svg {
        width: 24px;
      }

      & .logout {
        stroke: $red-90;
      }
    }

    &-title {
      color: $main-100;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.25px;
    }

    & .arrow {
      width: 24px;
      fill: #828282;
      transition: transform ease 0.2s;

      &-collapsed {
        transform: rotate(-180deg);
      }
    }
  }

  &__all-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;

    &--padding {
      padding-bottom: 20px;
    }
  }

  &__input {
    :deep .white {
      @media (min-width: 480px) {
        background: transparent;
      }

      &__input {
        @media (min-width: 480px) {
          padding: 17px 24px;
          padding-right: 55px;
        }

        &::placeholder {
          color: #b5b7c0;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.6;
        }
      }
    }
  }

  &__label {
    text-transform: none !important;
    letter-spacing: 0.24px;
  }
}

.burger-icon {
  padding: 10px;
  border-radius: 14px;
}

.collapsed {
  width: 100%;
  transition: height 0.3s cubic-bezier(0.3, 0, 0.6, 1);
}
</style>
