import { baseRequest } from '@/api/instance.js';
import auth from './services/auth';
import base from '@/api/services/base.js';
import tests from '@/api/services/tests.js';
import diary from '@/api/services/diary.js';
import triggers from '@/api/services/triggers.js';
import articles from '@/api/services/articles.js';
export const API = {
  auth: auth(baseRequest),
  base: base(baseRequest),
  tests: tests(baseRequest),
  diary: diary(baseRequest),
  triggers: triggers(baseRequest),
  articles: articles(baseRequest),
};
