<template>
  <div class="logo">
    <router-link class="logo__wrapper" :to="{ name: 'home' }" exact>
      <SVGComponent
        :key="$attrs.key"
        class="logo__wrapper-img"
        :path="{ name: 'logo', dir: 'global' }"
      />
    </router-link>
  </div>
</template>

<script setup></script>

<style lang="scss">
.logo {
  max-width: 151px;

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;

    &-img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
