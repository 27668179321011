import { useAuthStore } from '@/stores/AuthStore.js';
import { encrypt, decrypt } from '@/helpers/encrypt.js';

export const isSubscribed = (to, from, next) => {
  const auth = useAuthStore();

  isAuth(to, from, (authResult) => {
    if (typeof authResult === 'undefined') {
      if (to.name === 'subscription-expired' && auth.user.paid) {
        next({ name: 'home' });
      } else if (to.meta.subscription && !auth.user.paid) {
        next({ name: 'subscription-expired' });
      } else {
        next();
      }
    } else {
      next(authResult);
    }
  });
};

export const isAuth = (to, from, next) => {
  const auth = useAuthStore();
  const guestsAllowed = to.meta.guests;
  const params = to.query;

  if (guestsAllowed && !auth.isLoggedIn) {
    next();
  } else if (!guestsAllowed && !auth.isLoggedIn) {
    next({ name: 'sign-in', query: params });
  } else {
    next();
  }
};

export const setAuthLayout = (to, from, next) => {
  isAuth(to, from, next);
  to.meta.layout = localStorage.getItem('token') ? null : 'AuthLayout';
};

export const checkToken = (to, from, next) => {
  const token = to.query.token;

  if (!token) {
    next({ name: 'sign-in' });
  } else {
    next();
  }
};

export const isDemo = async (to, from, next) => {
  const { token } = to.query;
  const defaultLanguage = import.meta.env.VITE_DEFAULT_LOCALE;

  if (!token) {
    return next({ name: 'home' });
  }
  try {
    const auth = useAuthStore();
    const decryptedData = decrypt(token.replaceAll(' ', '+'), import.meta.env.VITE_CRYPT_KEY);

    await auth.signIn(JSON.parse(decryptedData));
    if (auth.user.language !== defaultLanguage) {
      await auth.updateProfileInfo({
        name: auth.user.name,
        timezone: auth.user.timezone,
        language: defaultLanguage,
      });
    }

    next({ name: 'home' });
  } catch (error) {
    console.error(error);
    next({ name: 'home' });
  }
};
