import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import router from './router/index.js';
import { Collapse } from 'vue-collapsed';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import SVGComponent from '@/components/shared/SVGComponent.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseSelect from '@/components/shared/BaseSelect/index.vue';
import BaseRangeInput from '@/components/ui/BaseRangeInput.vue';
import Vue3TouchEvents from 'vue3-touch-events';
import { disableContextMenu } from '@/directives/disable-context-menu.js';
import { vMaska } from 'maska';
import dayjsPlugin from '@/plugins/dayjs';
import i18n from './i18n';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(Vue3TouchEvents);
app.use(Toast, {
  position: POSITION.BOTTOM_LEFT,
  hideProgressBar: true,
});
app.use(i18n);
app.use(dayjsPlugin);

app.directive('mask', vMaska);
app.directive('disable-ctx-menu', disableContextMenu);

window.app.dayjs = app.config.globalProperties.$dayjs;

app.component('SVGComponent', SVGComponent);
app.component('BaseButton', BaseButton);
app.component('Collapse', Collapse);
app.component('BaseSelect', BaseSelect);
app.component('BaseRange', BaseRangeInput);

app.mount('#app');
