<template>
  <div class="overlay" @click.self="handleCloseModal" aria-modal="true" role="dialog" tabindex="-1">
    <component :is="modals.modalState.component" v-bind="modals.modalState.props" />
  </div>
</template>

<script setup>
import { useModalStore } from '@/stores/ModalStore.js';
import { onMounted, onUnmounted } from 'vue';
const modals = useModalStore();
const keydownListener = (event) => {
  if (event.key === 'Escape') handleCloseModal();
};

const handleCloseModal = () => {
  if (modals.popupState.component) return;
  modals.closeModal();
};

onMounted(() => {
  document.addEventListener('keydown', keydownListener);
});

onUnmounted(() => {
  document.removeEventListener('keydown', keydownListener);
});
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(54, 58, 68, 0.4);
  z-index: 101;

  display: grid;
  place-items: center;

  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 899px) {
    place-items: flex-end center;
  }
}
</style>
