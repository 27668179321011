function frPluralizationRules(choice) {
  if (choice === 0) {
    return 0;
  }

  if (choice === 1) {
    return 1;
  }

  return 2;
}

export default {
  fr: frPluralizationRules,
};
