<template>
  <div class="account">
    <h2 class="account__title">{{ $t('profile.profile_info') }}</h2>

    <div class="account__all-inputs">
      <base-input-field
        v-model="auth.inputs.profile.name.value"
        :id="auth.inputs.profile.name.key"
        :key="auth.inputs.profile.name.key"
        :input="auth.inputs.profile.name"
        :error="auth.inputs.profile.name.error || auth.inputs.profile.name.serverError"
        :form="auth.inputs.profile"
        :placeholder="$t('profile.accountPage.enterUserName')"
        :label="$t('profile.accountPage.name')"
        variant="white"
        class="account__input"
      />

      <base-select
        :options="timeZones"
        disabled
        :form="auth.inputs.profile.timezone"
        @change="emit('change-input-value', $event)"
        :label="$t('profile.accountPage.timeZone')"
        track-by="label"
      />

      <base-select
        :options="langs"
        :form="auth.inputs.profile.language"
        @change="emit('change-input-value', $event)"
        :label="$t('profile.accountPage.selectLanguage')"
        track-by="id"
      />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/AuthStore.js';
import BaseInputField from '@/components/ui/BaseInputField.vue';
const auth = useAuthStore();
const emit = defineEmits(['change-input-value']);

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  timeZones: {
    type: Array,
    default: () => [],
  },
  selectedLang: {
    type: Object,
  },
  selectedTimeZone: {
    type: Object,
  },
  langs: {
    type: Array,
    default: () => [],
  },
});
</script>

<style lang="scss" scoped>
@include slide-vertical-to-up(0.5s, slide-vertical-to-up, ease);
.account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    color: $main-100;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.16;
    letter-spacing: 0.25px;
    margin-bottom: 40px;
  }

  &__all-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__input {
    :deep .white {
      @media (min-width: 480px) {
        background: transparent;
      }

      &__input {
        @media (min-width: 480px) {
          padding: 17px 24px;
          padding-right: 55px;
        }

        &::placeholder {
          color: #b5b7c0;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.6;
        }
      }
    }
  }
}
</style>
