export function useDataLayer() {
  const setEventView = (name) => {
    if (window.dataLayer) {
      window.dataLayer.push({ event_view: name });
    }
  };

  const setEvent = (name) => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: `event_${name}` });
    }
  };

  return {
    setEventView,
    setEvent,
  };
}
