<template>
  <component
    :is="to ? RouterLink : tag"
    :class="[mainClass, { locked, reverse, disableLink }]"
    :to="to"
  >
    <template v-if="label">
      <span>{{ label }}</span>
      <SVGComponent
        v-if="icon"
        class="the-icon"
        :empty="icon.empty"
        :path="{ name: icon.name, dir: icon.dir, fill: icon.fill }"
        :style="{ fill: icon.fill, transform: `rotate(${icon.rotate}deg)` }"
      />
    </template>
    <template v-else>
      <SVGComponent
        class="the-icon"
        :empty="icon.empty"
        :path="{ name: icon.name, dir: icon.dir, fill: icon.fill }"
        :style="{ fill: icon.fill, transform: `rotate(${icon.rotate}deg)` }"
      />
    </template>
    <template>
      <slot />
    </template>
    <span v-if="divider">|</span>
  </component>
</template>

<script setup>
import { RouterLink } from 'vue-router';
import { computed } from 'vue';

const props = defineProps({
  tag: {
    type: String,
    default: 'button',
  },
  icon: {
    type: [Object, Boolean],
    default: () => {},
  },
  label: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: 'default',
  },
  isText: {
    type: Boolean,
    default: false,
  },
  disableLink: {
    type: Boolean,
    default: false,
  },
  divider: {
    type: Boolean,
    default: false,
  },
  reverse: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  to: {
    type: [Object, String],
    default: '',
  },
});

const mainClass = computed(
  () =>
    ({
      default: 'btn',
      inversed: 'btn inversed',
      transparent: 'transparent',
      icon: 'icon',
      black: 'btn black',
      text: 'text',
      lineText: 'text line',
      tertiary: 'tertiary',
      neutral: 'neutral',
      warning: 'warning',
    })[props.variant],
);
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 100px;
  background: $violet-90;
  padding: 13px 0;
  color: $white-color;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  transition:
    background 0.2s ease,
    box-shadow 0.2s ease;

  &.inversed {
    background: transparent;
    color: $violet-40;
    border: 2px solid $violet-40;

    &:hover {
      background: $violet-30 !important;
      border-color: $violet-30 !important;
    }
  }

  &.locked {
    background: $main-100;

    &:hover {
      background: $main-30;
    }
  }

  & span {
    color: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  & svg {
    width: 20px;
    height: 20px;
    fill: $main-100;
  }

  &:hover {
    background: $violet-30;
    color: #fff;
  }

  &[disabled] {
    background: rgba(227, 227, 227, 0.5);
    color: $gray-30;
    pointer-events: none;
    border: none;
  }
}

.transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  background: transparent;
  border-radius: 100px;
  color: $main-100;
  transition:
    background 0.2s ease,
    box-shadow 0.2s ease;
  gap: 8px;

  &:hover {
    background: #d7d1c6;
  }
}

.text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  border-radius: 100px;
  background: transparent;
  padding: 8px 0;
  gap: 10px;
  color: $violet-90;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  transition: all 0.25s ease;

  & span {
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-style: inherit;
    line-height: inherit;
  }

  &.line {
    gap: 5px;

    &:hover {
      gap: 7px;
      color: #9a82f0;

      & svg {
        fill: #9a82f0 !important;

        & :deep path {
          fill: #9a82f0 !important;
        }
      }
    }

    &:hover::before {
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: $violet-90;
      transition: width 0.3s ease-in-out;
    }
  }

  &[disabled] {
    pointer-events: none;
  }
}

.the-icon {
  width: 24px;
  height: 24px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eae6e2;
  width: fit-content;
  padding: 8px;
  border-radius: 14px;
}

.black {
  background: $main-100;
  color: #fff;

  &:hover {
    background: $main-30;
  }

  &[disabled] {
    color: #c0bfc3;
    background: rgba(227, 227, 227, 0.5);
    cursor: default;
  }
}

.reverse {
  flex-direction: row-reverse;
}

.disableLink {
  background: rgba(227, 227, 227, 0.5);
  color: $gray-30;
  pointer-events: none;
  cursor: default;
}

.tertiary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 100px;
  background: $violet-25;
  padding: 10px 0;
  color: $violet-40;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  transition:
    background 0.2s ease,
    box-shadow 0.2s ease;

  & span {
    color: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  &[disabled] {
    background: rgba(227, 227, 227, 0.5);
    color: $gray-30;
    pointer-events: none;
  }
}

.neutral {
  padding: 13px 24px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.warning {
  padding: 13px 24px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $red-90;
  border-radius: 100px;
  background: rgba(215, 60, 60, 0.05);

  & span {
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    color: inherit;
  }

  &[disabled] {
    background: rgba(227, 227, 227, 0.5);
    color: $gray-30;
    pointer-events: none;
  }
}
</style>
