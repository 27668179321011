<template>
  <div class="exit-modal">
    <div class="exit-modal__actions">
      <base-button
        variant="icon"
        :icon="{ name: 'cross', dir: 'global', fill: '#222' }"
        class="exit-modal__actions-close"
        @click="modals.closePopup('cancel')"
      />
    </div>
    <div class="exit-modal__content">
      <div class="exit-modal__description">
        <h2 class="exit-modal__title title title-md">
          {{ title }}
        </h2>
        <p class="exit-modal__text body body-lg">
          {{ text }}
        </p>
      </div>

      <div class="exit-modal__footer">
        <base-button
          variant="neutral"
          :label="$t('global.buttons.cancel')"
          @click="modals.closePopup('cancel')"
        />
        <base-button
          variant="warning"
          :label="$t('global.buttons.confirm')"
          :disabled="loading"
          @click="handleConfirm"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from '@/stores/ModalStore.js';
import { ref, toRefs } from 'vue';
const modals = useModalStore();
const props = defineProps({
  title: {
    type: String,
    default: 'Are you sure?',
  },
  text: {
    type: String,
    default: 'Are you sure want to exit?',
  },
  action: {
    type: Function,
  },
  confirm: {
    type: Function,
  },
  customData: {
    default: null,
  },
});

const { title, text, confirm, action, customData } = toRefs(props);
const loading = ref(false);
const handleConfirm = async () => {
  loading.value = true;
  if (confirm.value) {
    customData.value ? await confirm.value(customData.value) : await confirm.value();
  } else {
    action.value && action.value();
    modals.closeModal();
    modals.closePopup();
  }
};
</script>

<style lang="scss" scoped>
.exit-modal {
  max-width: 563px;
  width: 100%;
  border-radius: 20px;
  background: #f7f4f0;
  padding: 30px 20px 30px 20px;

  @media (max-width: 899px) {
    background: #fff;
    padding: 50px 18px 20px;
    border-radius: 20px 20px 0 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    &-back {
      transform: rotate(90deg);
      background: transparent;
    }

    &-close {
      background: transparent !important;
    }

    @media (max-width: 899px) {
      display: none;
    }
  }

  &__content {
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    @media (max-width: 899px) {
      padding: 0;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 10px;
  }

  &__text {
    text-align: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & button {
      max-width: 218px;
    }

    @media (max-width: 899px) {
      flex-direction: column-reverse;
      gap: 8px;

      & button {
        max-width: unset;
        background: #f7f4f0;
        border-radius: 13px;
        padding: 18px;
      }
    }
  }
}
</style>
