export const progressBarOptions = {
  low: {
    title: 'very_low',
    level: 'low',
    icon: 'cool',
    underlay: '#E5F4EC',
    bar: '#66C792',
    text: '#1DA45A',
  },
  mid: {
    title: 'low',
    level: 'mid',
    icon: 'good',
    underlay: '#EEF3DF',
    bar: '#B0CC61',
    text: '#B0CC61',
  },
  moderate: {
    title: 'mid',
    level: 'moderate',
    icon: 'normal',
    underlay: '#FFF4D9',
    bar: '#E09B32',
    text: '#CA8824',
  },
  high: {
    title: 'high',
    level: 'high',
    icon: 'bad',
    underlay: '#FFE6D7',
    bar: '#EB5668',
    text: '#CA2438',
  },
};
