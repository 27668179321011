<template>
  <div class="account">
    <div class="account__info">
      <div class="account__avatar">
        <p class="account__first-letter">{{ user.name.charAt(0) }}</p>
      </div>
      <span class="account__name title title-md">{{ user.name }}</span>
      <span class="account__email body body-xxl">{{ user.email }}</span>
      <span class="account__subscription"
        >{{ $t('profile.subscription')
        }}<span class="account__subscription-bold">{{ subscriptionMessage }}</span></span
      >
    </div>
    <div class="account__actions">
      <base-button
        variant="text"
        class="account__actions-support button-xl"
        reverse
        label="Support"
        :icon="{ name: 'support', dir: 'profile' }"
        :to="{ name: 'support' }"
        @click="setEvent('support')"
      />
      <base-button
        variant="text"
        class="account__actions-logout button-xl"
        reverse
        :label="$t('profile.accountPage.logout')"
        :icon="{ name: 'logout', dir: 'profile' }"
        @click.native="onLogout"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useDataLayer } from '@/composables/useDataLayer.js';

const { t } = useI18n();
const { setEvent } = useDataLayer();
const emit = defineEmits(['logout']);
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  subscriptionMessage: {
    type: String,
    default: '',
  },
});
const onLogout = () => {
  emit('logout');
};
</script>

<style lang="scss" scoped>
.account {
  border-right: 1px solid $gray-10;
  padding-top: 20px;

  &__first-letter {
    font-size: 50px;

    @media (max-width: 899px) {
      font-size: 20px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__avatar {
    width: 160px;
    height: 160px;
    background: $violet-10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 55px;
    margin-bottom: 32px;

    & .ava {
      fill: $main-100;
    }
  }

  &__name {
    color: $gray-100;
  }

  &__email {
    color: $gray-50;
  }

  &__subscription {
    margin-top: 18px;
    color: $gray-color;

    &-bold {
      font-weight: 700;
      font-size: 18px;
    }
  }

  &__actions {
    width: 100%;
    margin-top: 40px;

    &-logout {
      color: $red-90;
      stroke: $red-90;
    }

    &-support {
      color: #222;
    }
  }
}
</style>
