<template>
  <div class="burger">
    <div class="burger__header">
      <base-button
        variant="icon"
        :icon="{ name: 'cross', dir: 'global', fill: '#000' }"
        class="burger__header-cross"
        @click="modals.toggleMobileMenu"
      />

      <button class="burger__profile" @click="toggleProfile()">
        <SVGComponent class="burger__profile-user" :path="{ name: 'user', dir: 'profile' }" />
        <span class="burger__profile-name">
          {{ auth.user.name }}
        </span>
        <SVGComponent class="burger__profile-arrow" :path="{ name: 'arrow', dir: 'global' }" />
      </button>
    </div>

    <div class="burger__nav">
      <base-button
        :label="t('header.home')"
        :icon="{ name: 'home', dir: 'profile', fill: '#222' }"
        :to="{ name: 'home' }"
        reverse
        class="burger__nav-link"
        @click="setEvent('home')"
      />

      <base-button
        :label="t('header.diary')"
        :icon="{ name: 'diary', dir: 'profile', fill: '#222' }"
        :to="{ name: 'diary' }"
        reverse
        class="burger__nav-link"
        @click="setEvent('diary')"
      />

      <div class="burger__nav-products">
        <base-button
          tag="a"
          :label="t('header.products')"
          :icon="{ name: 'layer', dir: 'profile', fill: '#222' }"
          reverse
          class="burger__nav-link"
          :class="{ 'router-link-exact-active': isDepressionPage }"
        />
        <ul class="burger__nav-products-list">
          <li
            v-for="(product, productIndex) in main.products"
            :key="productIndex"
            class="burger__nav-products-item"
            @click.stop
          >
            <base-button
              :label="t(product.title)"
              :disabled="product.lock ? product.lock : null"
              :icon="product.lock ? { name: 'lock', dir: 'global', fill: '#B5B5B5' } : ''"
              :to="{ name: product.id }"
              class="burger__nav-products-item-btn"
              @click="setEvent('products')"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="burger__logout">
      <base-button
        variant="text"
        class="burger__logout-btn button-xl"
        reverse
        :label="t('profile.accountPage.logout')"
        :icon="{ name: 'logout', dir: 'profile' }"
        @click.native="onLogout"
      />
    </div>

    <TheFooter class="burger__footer" />
  </div>
</template>

<script setup>
import TheFooter from '@/components/TheFooter.vue';

import { computed } from 'vue';

import { useAuthStore } from '@/stores/AuthStore.js';
import { useMainStore } from '@/stores/MainStore.js';
import { useModalStore } from '@/stores/ModalStore.js';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useDataLayer } from '@/composables/useDataLayer.js';

const auth = useAuthStore();
const modals = useModalStore();
const main = useMainStore();
const router = useRouter();
const { t } = useI18n();
const { setEvent } = useDataLayer();

const onLogout = () => {
  auth.logout();
};
const toggleProfile = () => {
  modals.toggleMobileMenu();
  router.push({ name: 'account' });
};

const isDepressionPage = computed(() => router.currentRoute.value.name === 'depression-test');
</script>

<style lang="scss" scoped>
.burger {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  height: 100%;
  width: 80%;

  max-width: 500px;

  overflow: scroll;

  background-color: #f7f4f0;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 20px;
    width: 100%;

    border-bottom: 1px solid #e0e5de;

    &-cross {
      background: transparent !important;
      margin-left: auto !important;
      margin-bottom: 28px !important;
      padding: 0 !important;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 25px 20px 16px;
    width: 100%;

    &-link {
      width: 100% !important;
      justify-content: flex-end !important;
      padding: 12px !important;
      gap: 10px !important;
      color: #222 !important;
      border-radius: 14px !important;
      background: transparent !important;

      :deep & svg {
        width: 24px;
        height: 24px;
      }

      &.router-link-exact-active {
        color: #fff !important;
        background: $violet-40 !important;
        :deep & svg {
          fill: #fff !important;
        }
      }
    }

    &-products {
      &-list {
        display: flex;
        flex-direction: column;
        list-style: none;
        gap: 8px;
        margin-top: 5px;
      }

      &-item {
        padding-left: 44px;

        &-btn {
          position: relative;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: 1.85 !important;
          color: #222 !important;
          padding: 0 10px !important;
          justify-content: flex-start;
          background: transparent !important;
          gap: 8px;

          &[disabled] {
            color: $gray-color !important;
          }

          &.router-link-exact-active {
            &::before {
              content: '';
              width: 4px;
              height: 100%;
              background: $violet-40;
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 14px;
            }
          }
        }
      }
    }
  }

  &__logout {
    width: 100%;

    padding: 10px 24px;

    border-top: 1px solid #e0e5de;
    border-bottom: 1px solid #e0e5de;

    &-btn {
      color: #222 !important;
      stroke: $red-90;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.6;
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
    height: 46px;
    border-radius: 14px;
    background: #eae6e2;

    transition: background 0.2s ease;

    &-user {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      fill: #222222;
    }

    &-name {
      margin-right: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
    }

    &-arrow {
      margin-left: auto;
      width: 24px;
      height: 24px;
      fill: #222222;
    }

    &:hover {
      background: #d9d6d2;
    }
  }

  &__footer {
    padding-top: 10px;

    :deep & .footer__wrapper {
      background: transparent;
    }

    :deep & .logo {
      width: 120px;
    }

    :deep & .footer__back {
      display: none;
    }
  }
}
</style>
