<template>
  <div class="changePassword">
    <h2 class="changePassword__title">{{ $t('profile.accountPage.changePassword') }}</h2>

    <div class="changePassword__all-inputs">
      <base-input-field
        v-for="input in auth.inputs.profile_passwords"
        v-model="input.value"
        :id="input.key"
        :key="input.key"
        :input="input"
        :error="input.error || input.serverError"
        :form="auth.inputs.profile_passwords"
        :placeholder="$t(`profile.changePasswordPage.${input.key}.placeholder`)"
        :label="$t(`profile.changePasswordPage.${input.key}.label`)"
        variant="white"
        class="changePassword__input"
      />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/AuthStore.js';
import BaseInputField from '@/components/ui/BaseInputField.vue';
const auth = useAuthStore();
const emit = defineEmits(['change-input-value']);
</script>

<style lang="scss" scoped>
.changePassword {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    color: $main-100;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.16;
    letter-spacing: 0.25px;
    margin-bottom: 40px;
  }

  &__all-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__input {
    :deep & .white {
      @media (min-width: 480px) {
        background: transparent;
      }

      &__input {
        @media (min-width: 480px) {
          padding: 17px 24px;
          padding-right: 55px;
        }

        &::placeholder {
          color: #b5b7c0;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.6;
        }
      }
    }
  }
}
</style>
