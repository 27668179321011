import CryptoJS from 'crypto-js';

export const encrypt = (message, secretKey) => {
  const key = CryptoJS.PBKDF2(secretKey, 'salt', { keySize: 256 / 32 });
  const encrypted = CryptoJS.AES.encrypt(message, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const decrypt = (ciphertext, secretKey) => {
  const key = CryptoJS.PBKDF2(secretKey, 'salt', { keySize: 256 / 32 });
  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
