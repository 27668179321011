<template>
  <div :disabled="disabled || null" @click.stop>
    <h3 v-if="label" class="input__label label label-xl">{{ label }}</h3>
    <div
      :disabled="disabled || null"
      :class="[{ active: isToggled }, 'input']"
      @click.stop="toggle"
    >
      <span class="input__field">
        {{ selected.label }}
      </span>
      <SVGComponent
        v-if="!disabled"
        :path="{ name: 'angle-down', dir: 'global', fill: '#222' }"
        :class="[{ active: isToggled }, 'input__arrow']"
      />
      <transition name="local-fade" appear mode="out-in">
        <ul v-if="isToggled && !disabled" class="input__options">
          <li
            v-for="{ label, id } of formattedInputOptions"
            :key="id"
            :class="[{ active: selected.id === id }, 'input__option']"
            @click.stop="setValue({ id, label })"
          >
            {{ label }}
          </li>
        </ul>
      </transition>
      <div v-if="isToggled" class="overlay" @click.stop="toggle" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import { useToggler } from '@/composables/useToggler.js';

const { isToggled, toggle } = useToggler();

const { options, form, label, trackBy, key } = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  form: {
    type: Object,
    default: () => {},
  },
  trackBy: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const initValue = computed(() => form?.value);

const emit = defineEmits(['change']);

const formattedInputOptions = computed(() =>
  options.map((option, idx) => ({
    id: option.id ? option.id : idx + 1,
    label: option.label ? option.label : option.name || option.title || option.value || option,
  })),
);
const formattedInitValue = computed(() =>
  formattedInputOptions.value.find(
    (option) => option.label === initValue.value || option.id === initValue.value,
  ),
);

const selected = ref(
  formattedInitValue.value || {
    id: null,
    label: null,
  },
);

watch(formattedInitValue, () => {
  selected.value = formattedInitValue.value;
});

const setValue = ({ id, label }) => {
  selected.value = {
    id,
    label,
  };
  const payload = trackBy
    ? { key: form.key, value: selected.value[trackBy] }
    : { key: form.key, value: selected.value.label };
  emit('change', payload);
  toggle();
};
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  padding: 18px 20px 18px 25px;
  border-radius: 47px;
  border: 2px solid #ebeaed;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  min-height: 60px;

  color: inherit;

  cursor: pointer;

  transition: all 0.2s ease;

  &[disabled] {
    background: #f8f8f8;
  }

  &:hover {
    border-color: $violet-20;
  }

  @media (max-width: 899px) {
    padding: 10px 20px 10px 25px;
    min-height: 48px;
    background: #fff;
  }

  &.active {
    cursor: default;
    border-color: $violet-20;

    z-index: 100;

    border-radius: 30px 30px 0 0;
  }

  &__field {
    display: block;
    width: 100%;
    height: 100%;

    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
  }

  &__arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;

    transition:
      transform ease 0.2s,
      fill ease 0.2s;

    &.active {
      transform: translateY(-50%) rotate(-180deg);
      fill: #fff;
    }
  }

  &__label {
    margin-bottom: 7px;
    text-transform: uppercase;
  }

  &__options {
    position: absolute;
    top: 100%;
    left: -2px;
    right: -2px;
    list-style: none;

    z-index: 100;

    display: flex;
    flex-direction: column;

    border-radius: 0 0 30px 30px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-color: $violet-20;
    background: #fff;
    box-shadow:
      0px 2.7672564983px 2.2138051987px 0px rgba(0, 0, 0, 0.02),
      0px 6.6501021385px 5.3200817108px 0px rgba(0, 0, 0, 0.03),
      0px 12.5215520859px 10.017241478px 0px rgba(0, 0, 0, 0.04),
      0px 22.336309433px 17.8690471649px 0px rgba(0, 0, 0, 0.04),
      0px 41.7776107788px 33.422088623px 0px rgba(0, 0, 0, 0.05),
      0px 100px 80px 0px rgba(0, 0, 0, 0.07);
  }

  &__option {
    padding: 18px 20px;
    cursor: pointer;

    &:hover {
      background: rgba(131, 101, 237, 0.3);
    }

    &.active {
      background: $violet-40;
      color: #fff;
    }

    &:last-child {
      border-radius: 0 0 30px 30px;
    }

    @media (max-width: 480px) {
      padding: 8px 20px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  z-index: 99;
}
</style>
