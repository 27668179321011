<template>
  <div class="calendar">
    <div class="calendar__monthSelector">
      <div class="calendar__monthSelector__selector prev">
        <SVGComponent class="icon" :path="{ name: 'arrow', dir: 'global' }" />
      </div>
      <span class="calendar__monthSelector__month">
        {{ $d(new Date(), 'shortMonth') }}
      </span>
      <div class="calendar__monthSelector__selector">
        <SVGComponent class="icon" :path="{ name: 'arrow', dir: 'global' }" />
      </div>
    </div>
    <div class="calendar__weekdays">
      <div
        class="calendar__weekdays__item"
        v-for="(weekday, weekdayIndex) in weekdays"
        :key="weekdayIndex"
      >
        {{ t(`home.diary.weekday.${weekday}`)[0].toUpperCase() }}
      </div>
    </div>
    <div class="calendar__days">
      <div
        class="calendar__days__item"
        :class="{ active: day.active, disabled: day.disabled }"
        v-for="(day, dayIndex) in days"
        :key="dayIndex"
      >
        <div class="calendar__days__item-date">{{ day.date.getDate() }}</div>
        <div v-if="!day.disabled" class="calendar__days__item-icon" :class="day.status">
          <SVGComponent :path="{ name: day.status, dir: 'moods' }" />
        </div>
      </div>
    </div>
    <SVGComponent class="smile-back" :path="{ name: 'smile-yellow', dir: 'home' }" />
    <SVGComponent class="green-smile" :path="{ name: 'smile-green', dir: 'home' }" />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useSwitchLanguage } from '@/composables/useSwitchLanguage.js';
import { computed } from 'vue';

const { usersLocale } = useSwitchLanguage();

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const moods = ['sorrow', 'sad', 'normal', 'good', 'cool'];
const days = [...Array(21)].map((v, i) => {
  return {
    date: new Date(new Date().setDate(new Date().getDate() - (17 - i))),
    status: moods[Math.floor(Math.random() * (moods.length - 1 - 0 + 1)) + 0],
    active: 17 - i === 0,
    disabled: 17 - i < 0,
  };
});

const { t } = useI18n();

const calendarPosition = computed(() => (usersLocale.value === 'en' ? '10px' : '-35px'));
</script>

<style lang="scss">
.calendar {
  padding: 22px 28px;
  border-radius: 21px;
  background: #fff;

  width: 364px;
  position: absolute;
  top: 30px;
  right: -15px;
  z-index: 10;

  @media (max-width: 1250px) {
    width: 225px;
    height: 187px;
    top: v-bind(calendarPosition);
    right: -90px;
  }

  &__monthSelector {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 9px;

    &__month {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__selector {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      cursor: pointer;

      .icon {
        fill: #000000;
        width: 100%;
      }

      &.prev {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    margin-bottom: 9px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a7b9ab;
      font-size: 11px;
      line-height: 18px;
    }
  }

  &__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0 auto 5px;
      padding: 0 4.5px 4.5px 4.5px;
      width: 100%;
      max-width: 46px;
      height: 58px;
      border-radius: 18px;
      border: 3px solid transparent;

      &:nth-last-child(-n + 7) {
        margin-bottom: 0;
      }

      &-date {
        margin-bottom: 1.25px;
        color: #233916;
        font-size: 12px;
        line-height: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 31px;
        height: 31px;

        border-radius: 50%;

        svg {
          width: 22px;
          height: 22px;
        }

        &.sorrow {
          border: 1px solid #b0b0b0;
          background: rgba(176, 176, 176, 0.3);
          box-shadow: 0 3.65px 3.65px 0 rgba(176, 176, 176, 0.5);
        }

        &.sad {
          border: 1px solid #7ea6e2;
          background: rgba(126, 166, 226, 0.3);
          box-shadow: 0 3.65px 3.65px 0 rgba(126, 166, 226, 0.5);
        }

        &.normal {
          border: 1px solid #ffd66c;
          background: rgba(255, 214, 108, 0.3);
          box-shadow: 0 3.65px 3.65px 0 rgba(255, 214, 108, 0.5);
        }

        &.good {
          border: 1px solid #e0f075;
          background: rgba(228, 242, 134, 0.3);
          box-shadow: 0 3.65px 3.65px 0 rgba(228, 242, 134, 0.5);
        }

        &.cool {
          border: 1px solid #82ba5f;
          background: rgba(130, 186, 95, 0.3);
          box-shadow: 0 3.65px 3.65px 0 rgba(130, 186, 95, 0.5);
        }
      }

      &.active {
        border: 3px solid #dddfe0;

        .calendar__days__item-icon {
          border: none;
          background: rgba(255, 255, 255, 0.3);
          box-shadow: 0 3.65px 3.65px 0 rgba(142, 64, 75, 0.3);
        }
      }
    }
  }

  @media (max-width: 1250px) {
    padding: 12px 16px;

    &__monthSelector {
      margin-bottom: 5px;

      &__month {
        font-size: 10px;
      }

      &__selector {
        width: 12px;
        height: 12px;
      }
    }

    &__weekdays {
      margin-bottom: 5px;

      &__item {
        font-size: 7px;
        line-height: 9px;
      }
    }

    &__days {
      &__item {
        padding: 0 2.5px 2.5px 2.5px;
        max-width: 25px;
        height: 33px;
        border: 2px solid transparent;

        &-date {
          margin-bottom: 0;
          font-size: 7px;
          line-height: 10px;
        }

        &-icon {
          width: 18px;
          height: 18px;

          svg {
            width: 12.5px;
            height: 12.5px;
          }
        }

        &.active {
          border: 2px solid #dddfe0;
        }
      }
    }
  }
}

.smile-back {
  position: absolute;

  width: 90px;
  height: 92px;
  top: -20px;
  left: -20px;

  z-index: 15;

  @media (max-width: 1250px) {
    width: 70px;
    right: -35px;
    top: -100px;
    left: 80px;
  }
}

.green-smile {
  position: absolute;

  width: 118px;
  height: 118px;
  right: 0;
  top: 80%;

  transform: rotate(-25deg);

  z-index: 10;

  @media (max-width: 1250px) {
    width: 70px;
    top: unset;
    bottom: -50px;
    right: 65px;
  }
}
</style>
