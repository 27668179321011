<template>
  <div
    class="overlay"
    @click.self="modals.toggleMobileMenu"
    aria-modal="true"
    role="dialog"
    tabindex="-1"
  >
    <Transition name="slide-horizontal-to-left" appear mode="out-in">
      <MobileMenu v-if="modals.mobileMenuState.component" />
    </Transition>
  </div>
</template>

<script setup>
import { useModalStore } from '@/stores/ModalStore.js';
import MobileMenu from '@/components/MobileMenu.vue';

const modals = useModalStore();
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/mixins';
@include local-fade(0.2s, local-fade);
@include slide-vertical-to-up(0.5s, slide-vertical-to-up, ease);
@include slide-horizontal-to-left(0.5s, slide-horizontal-to-left, ease);

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
</style>
