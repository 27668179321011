<template>
  <div class="article" :title="t(article.title)">
    <SVGComponent class="img-back" :path="{ name: 'circle', dir: 'home' }" />
    <SVGComponent
      class="img-back right"
      :class="article.backImg"
      :path="{ name: 'circle', dir: 'home' }"
    />

    <img :src="article.preview" class="article__main-img" :alt="article.title" />

    <div class="article__info">
      <base-button
        variant="text"
        class="article__title title title-md"
        :label="t(article.title)"
        @click="handleArticle(article.slug)"
      />
      <p class="article__text body body-md">{{ t(article.description) }}</p>

      <base-button
        class="article__button button button-md"
        :label="t('home.articles.readMore')"
        :icon="{ name: 'arrow', dir: 'global', fill: '#222' }"
        variant="transparent"
        @click="handleArticle(article.slug)"
      />
    </div>
  </div>
</template>

<script setup>
import { useMainStore } from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import BaseButton from '@/components/ui/BaseButton.vue';
const main = useMainStore();
const props = defineProps({
  article: Object,
});

const { t } = useI18n();
const handleArticle = async (slug) => {
  main.getCurrentArticle(slug);
};
</script>

<style lang="scss">
.article {
  display: flex;
  align-items: center;
  padding: 24px 40px 24px 20px;
  width: 100%;
  height: 270px;

  border-radius: 25px;
  background: #f1ece2;
  overflow: hidden;
  position: relative;

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    z-index: 10;
  }

  &__main-img {
    margin-right: 20px;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    max-width: 207px;
    max-height: 217px;

    @media (max-width: 899px) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 10px !important;
    font-weight: 700;
    line-height: 1.31 !important;
    font-size: 22px !important;
    text-align: left;
    color: #222 !important;
    padding: 0 !important;

    & span {
      height: 55px;
      font-style: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      max-height: 55px;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    @media (max-width: 768px) {
      font-size: 20px !important;
    }
  }

  &__text {
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 70px;
    -webkit-line-clamp: 3;

    @media (max-width: 480px) {
      height: 65px;
      -webkit-line-clamp: 3;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto !important;
    width: 125px !important;

    .arrow {
      width: 24px;
      height: 24px;
      margin-left: 8px;
      fill: #222222;
    }
  }

  @media (max-width: 480px) {
    padding: 24px 20px 18px;
    max-width: 100%;
    height: auto;

    &__info {
      .title {
        font-size: 18px;
      }
      .text {
        height: 65px;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
}

.img-back {
  position: absolute;
  bottom: -120px;
  left: -170px;
  width: 320px;
  height: 255px;

  &.right {
    left: auto;
    bottom: auto;
    top: -150px;
    right: -170px;
    transform: rotate(-85deg);
  }

  &.revert {
    bottom: auto;
    top: -120px;

    &.right {
      top: auto;
      bottom: -70px;
      right: -210px;
    }
  }
}
</style>
