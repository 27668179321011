<template>
  <div class="main-wrapper" :class="$attrs.class" v-if="label && !input?.textarea">
    <label class="label">
      {{ label }}
      <span v-if="required" class="label-required"> ({{ t('global.inputs.required') }}) </span>
      <slot name="add" />
    </label>
    <div :class="mainClass">
      <SVGComponent v-if="icon" :path="icon" />
      <input
        v-mask:[mask]
        :type="input?.hide ? 'password' : 'text'"
        :name="input?.key || id"
        :placeholder="placeholder"
        :value="modelValue"
        :class="`${mainClass}__input`"
        :maxlength="maxLength"
        v-on:[event]="handleInput"
        @focus="onFocus"
      />
      <SVGComponent
        v-if="input?.key.includes('password')"
        class="eye-btn"
        :data="{ view: !input?.hide }"
        :path="{ name: 'eye', dir: 'global' }"
        @click="showHidePassword"
      />
      <base-button
        v-if="!input?.key.includes('password') && modelValue"
        variant="icon"
        :icon="{ name: 'cross', dir: 'global', fill: '#222' }"
        class="close-btn"
        @click="emit('update:modelValue', '')"
      />
      <span
        v-if="maxLength && modelValue.length"
        :class="[{ fulfilled: modelValue.length >= maxLength }, `${mainClass}__counter`]"
      >
        {{ modelValue.length }}/{{ maxLength }}
      </span>
    </div>
    <div v-if="error" class="error">
      <SVGComponent class="icon" :path="{ name: 'error', dir: 'global', fill: '#F8564B' }" />
      <span>{{ errorText || input?.serverError }}</span>
    </div>
    <p class="help-message">{{ helpMessage }}</p>
  </div>
  <div v-else-if="!label && !input?.textarea" :class="mainClass">
    <SVGComponent v-if="icon" :path="icon" />
    <input
      v-mask:[mask]
      :type="input?.key || 'text'"
      :placeholder="placeholder"
      :name="input?.key || id"
      :value="modelValue"
      :class="`${mainClass}__input`"
      :maxlength="maxLength"
      v-on:[event]="handleInput"
      @focus="onFocus"
    />
    <span
      v-if="maxLength && modelValue.length"
      :class="[{ fulfilled: modelValue.length >= maxLength }, `${mainClass}__counter`]"
    >
      {{ modelValue.length }}/{{ maxLength }}
    </span>
  </div>
  <div v-if="error && maxLength && !input?.textarea" class="error">
    <SVGComponent class="icon" :path="{ name: 'error', dir: 'global', fill: '#F8564B' }" />
    <span>{{ errorText || input?.serverError }}</span>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { getInputErrorMessage } from '@/helpers/utils.js';
import { useI18n } from 'vue-i18n';

const { modelValue, variant, icon, error, id, input, form } = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  icon: {
    type: Object,
    default: '',
  },
  mask: {
    type: Object,
    default: () => {},
  },
  variant: {
    type: String,
    default: 'default',
  },
  id: {
    type: String,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  helpMessage: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  maxLength: {
    type: Number,
    default: false,
  },
  input: {
    type: Object,
    default: () => {},
  },
  form: {
    type: Object,
    default: () => {},
  },
  event: {
    type: String,
    default: 'input',
  },
});

const mainClass = computed(
  () =>
    ({
      default: 'base-search-field',
      white: 'white',
    })[variant],
);

const borderColor = computed(() => (error ? '#F8564B' : '#EBEAED'));

const { t } = useI18n();

const emit = defineEmits(['update:modelValue', 'change']);

const errorText = computed(() => {
  const { message } = getInputErrorMessage(`${id}_${error}`, `errors.unknowns.input.${id}`);

  return input.error ? t(message) : input.serverError;
});

const onFocus = () => {
  if (input) {
    form[input.key].error = '';
    form[input.key].serverError = '';
  }
};

const handleInput = (event) => {
  const value = event.target.value;
  emit('update:modelValue', value);
};

const showHidePassword = () => {
  form[input.key].hide = !form[input.key].hide;
};

watch(
  () => modelValue,
  (value) => {
    if (!value && input) form[input.key].error = '';
  },
);
</script>

<style lang="scss" scoped>
.main-wrapper {
  position: relative;
}

.base-search-field {
  border-radius: 45px;
  background: #eee9e2;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;

  position: relative;

  & svg {
    width: 24px;
    height: 24px;
  }

  &__input {
    all: unset;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    width: 100%;

    &:autofill {
      border-radius: 100px;
    }

    &:-webkit-autofill {
      border-radius: 100px;
    }

    &::placeholder {
      all: unset;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      color: rgba(0, 0, 0, 0.5);
      text-overflow: ellipsis;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &:focus-visible {
      outline: none;
      border: none;
    }
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: 5%;
    font-size: 12px;
  }
}

.white {
  position: relative;
  border-radius: 100px;
  border: 2px solid v-bind(borderColor);
  background: #fff;

  & .error {
    border-color: #f8564b;
  }

  position: relative;

  &__input {
    all: unset;
    width: 100%;
    padding: 11px 20px;
    padding-right: 50px;
    box-sizing: border-box;

    &:autofill {
      border-radius: 100px;
    }

    &:-webkit-autofill {
      border-radius: 100px;
    }

    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.15;
      color: $gray-color;
      text-overflow: ellipsis;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &:active {
      outline: none;
    }

    &:focus-visible {
      outline: none;
      border: none;
    }
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: 5%;
    font-size: 12px;
  }
}

.fulfilled {
  color: $red-90;
  font-weight: 700;
}

.label {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 7px;

  &-required {
    color: $gray-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0;
  }

  :deep & .route {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: none;
    margin-left: auto;
  }
}

.help-message {
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 10px;
}

.error {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;

  & svg {
    width: 16px;
    height: 16px;
  }

  & span {
    color: #f8564b;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
  }
}

.close-btn {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  background: transparent;
}

.eye-btn {
  position: absolute;
  top: calc(50% - 24px / 2);
  right: 24px;
  width: 24px;
  height: 24px;

  cursor: pointer;

  transition: transform 0.2s ease;
}
</style>
