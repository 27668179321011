import { createI18n } from 'vue-i18n';
import en from './locales/en/index.json';
import pluralRules from '@/i18n/rules/pluralization';
import datetimeFormats from '@/i18n/rules/datetime.js';
export default createI18n({
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallback: import.meta.env.VITE_FALLBACK_LOCALE,
  globalInjection: true,
  legacy: false,
  messages: { en },
  pluralRules,
  datetimeFormats,
});
