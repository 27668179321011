export default {
  email_required: {
    message: 'errors.input.email.empty',
    type: 'error',
  },
  email_email: {
    message: 'errors.input.email.incorrect',
    type: 'error',
  },
  phone_numeric: {
    message: 'errors.input.phone.incorrect',
    type: 'error',
  },
  phone_minLength: {
    message: 'errors.input.phone.short',
    type: 'error',
  },
  phone_maxLength: {
    message: 'errors.input.phone.long',
    type: 'error',
  },
  email_minLength: {
    message: 'errors.input.email.short',
    type: 'error',
  },
  email_maxLength: {
    message: 'errors.input.email.long',
    type: 'error',
  },
  name_required: {
    message: 'errors.input.name.empty',
    type: 'error',
  },
  name_alphaNum: {
    message: 'errors.input.name.incorrect',
    type: 'error',
  },
  name_minLength: {
    message: 'errors.input.name.short',
    type: 'error',
  },
  name_maxLength: {
    message: 'errors.input.name.long',
    type: 'error',
  },

  password_confirmation_required: {
    message: 'errors.input.password.empty',
    type: 'error',
  },
  password_confirmation_minLength: {
    message: 'errors.input.password.short',
    type: 'error',
  },
  password_confirmation_maxLength: {
    message: 'errors.input.password.long',
    type: 'error',
  },
  password_confirmation_sameAs: {
    message: 'errors.input.password.dontMatch',
    type: 'error',
  },

  password_credentials: {
    message: 'errors.input.password.credentials',
    type: 'error',
  },

  password_sameAs: {
    message: 'errors.input.password.dontMatch',
    type: 'error',
  },
  password_required: {
    message: 'errors.input.password.empty',
    type: 'error',
  },
  password_minLength: {
    message: 'errors.input.password.short',
    type: 'error',
  },
  password_maxLength: {
    message: 'errors.input.password.long',
    type: 'error',
  },
  passRepeat_sameAs: {
    message: 'errors.input.password.dontMatch',
    type: 'error',
  },
  passRepeat_required: {
    message: 'errors.input.password.empty',
    type: 'error',
  },
  passRepeat_minLength: {
    message: 'errors.input.password.short',
    type: 'error',
  },
  passRepeat_maxLength: {
    message: 'errors.input.password.long',
    type: 'error',
  },
  current_password_required: {
    message: 'errors.input.password.empty',
    type: 'error',
  },
  current_password_incorrect: {
    message: 'errors.input.password.incorrect',
    type: 'error',
  },
  current_password_minLength: {
    message: 'errors.input.password.short',
    type: 'error',
  },
  current_password_maxLength: {
    message: 'errors.input.password.long',
    type: 'error',
  },

  new_password_sameAs: {
    message: 'errors.input.password.dontMatch',
    type: 'error',
  },
  new_password_notSameAs: {
    message: 'errors.input.password.match',
    type: 'error',
  },
  new_password_incorrect: {
    message: 'errors.input.password.incorrect',
    type: 'error',
  },
  new_password_required: {
    message: 'errors.input.password.empty',
    type: 'error',
  },
  new_password_minLength: {
    message: 'errors.input.password.short',
    type: 'error',
  },
  new_password_maxLength: {
    message: 'errors.input.password.long',
    type: 'error',
  },

  new_password_confirmation_sameAs: {
    message: 'errors.input.password.dontMatch',
    type: 'error',
  },
  new_password_confirmation_incorrect: {
    message: 'errors.input.password.incorrect',
    type: 'error',
  },
  new_password_confirmation_required: {
    message: 'errors.input.password.empty',
    type: 'error',
  },
  new_password_confirmation_minLength: {
    message: 'errors.input.password.short',
    type: 'error',
  },
  new_password_confirmation_maxLength: {
    message: 'errors.input.password.long',
    type: 'error',
  },
  trigger_minLength: {
    message: 'errors.input.trigger.short',
    type: 'error',
  },
  trigger_maxLength: {
    message: 'errors.input.trigger.long',
    type: 'error',
  },
  message_minLength: {
    message: 'errors.input.message.short',
    type: 'error',
  },
  message_maxLength: {
    message: 'errors.input.message.long',
    type: 'error',
  },
  message_required: {
    message: 'errors.input.message.empty',
    type: 'error',
  },
};
